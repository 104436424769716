export const MAPS_API = "AIzaSyDqm62_JJvnvLK6ob9UKY1oQ9LTx0ul_VA";

/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* LOGSACCESS */
export const SET_LOGSACCESS_LIST = "SET_LOGSACCESS_LIST";
export const ADD_NEW_LOGSACCESS = "ADD_NEW_LOGSACCESS";
export const UPDATE_LOGSACCESS = "UPDATE_LOGSACCESS";
export const DELETE_LOGSACCESS = "DELETE_LOGSACCESS";
export const OPEN_LOGSACCESS_FORM = "OPEN_LOGSACCESS_FORM";
export const CLOSE_LOGSACCESS_FORM = "CLOSE_LOGSACCESS_FORM";
export const EDIT_SELECTED_LOGSACCESS = "EDIT_SELECTED_LOGSACCESS";
export const LOGSACCESS_FORM_TOOGLE_LOADING = "LOGSACCESS_FORM_TOOGLE_LOADING";
/* <---- LOGSACCESS ----> */

/* OTHERADITIONALINFORMATION */
export const SET_OTHERADITIONALINFORMATION_LIST = "SET_OTHERADITIONALINFORMATION_LIST";
export const ADD_NEW_OTHERADITIONALINFORMATION = "ADD_NEW_OTHERADITIONALINFORMATION";
export const UPDATE_OTHERADITIONALINFORMATION = "UPDATE_OTHERADITIONALINFORMATION";
export const DELETE_OTHERADITIONALINFORMATION = "DELETE_OTHERADITIONALINFORMATION";
export const OPEN_OTHERADITIONALINFORMATION_FORM = "OPEN_OTHERADITIONALINFORMATION_FORM";
export const CLOSE_OTHERADITIONALINFORMATION_FORM = "CLOSE_OTHERADITIONALINFORMATION_FORM";
export const EDIT_SELECTED_OTHERADITIONALINFORMATION = "EDIT_SELECTED_OTHERADITIONALINFORMATION";
export const OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING = "OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING";
/* <---- OTHERADITIONALINFORMATION ----> */

/* ADITIONALINFORMATION */
export const SET_ADITIONALINFORMATION_LIST = "SET_ADITIONALINFORMATION_LIST";
export const ADD_NEW_ADITIONALINFORMATION = "ADD_NEW_ADITIONALINFORMATION";
export const UPDATE_ADITIONALINFORMATION = "UPDATE_ADITIONALINFORMATION";
export const DELETE_ADITIONALINFORMATION = "DELETE_ADITIONALINFORMATION";
export const OPEN_ADITIONALINFORMATION_FORM = "OPEN_ADITIONALINFORMATION_FORM";
export const CLOSE_ADITIONALINFORMATION_FORM = "CLOSE_ADITIONALINFORMATION_FORM";
export const EDIT_SELECTED_ADITIONALINFORMATION = "EDIT_SELECTED_ADITIONALINFORMATION";
export const ADITIONALINFORMATION_FORM_TOOGLE_LOADING = "ADITIONALINFORMATION_FORM_TOOGLE_LOADING";
/* <---- ADITIONALINFORMATION ----> */

/* IVA */
export const SET_IVA_LIST = "SET_IVA_LIST";
export const ADD_NEW_IVA = "ADD_NEW_IVA";
export const UPDATE_IVA = "UPDATE_IVA";
export const DELETE_IVA = "DELETE_IVA";
export const OPEN_IVA_FORM = "OPEN_IVA_FORM";
export const CLOSE_IVA_FORM = "CLOSE_IVA_FORM";
export const EDIT_SELECTED_IVA = "EDIT_SELECTED_IVA";
export const IVA_FORM_TOOGLE_LOADING = "IVA_FORM_TOOGLE_LOADING";
/* <---- IVA ----> */

/* ROLE */
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const ADD_NEW_ROLE = "ADD_NEW_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const OPEN_ROLE_FORM = "OPEN_ROLE_FORM";
export const CLOSE_ROLE_FORM = "CLOSE_ROLE_FORM";
export const EDIT_SELECTED_ROLE = "EDIT_SELECTED_ROLE";
export const ROLE_FORM_TOOGLE_LOADING = "ROLE_FORM_TOOGLE_LOADING";
/* <---- ROLE ----> */

/* CONSULTINVOICE */
export const SET_CONSULTINVOICE_LIST = "SET_CONSULTINVOICE_LIST";
export const ADD_NEW_CONSULTINVOICE = "ADD_NEW_CONSULTINVOICE";
export const UPDATE_CONSULTINVOICE = "UPDATE_CONSULTINVOICE";
export const DELETE_CONSULTINVOICE = "DELETE_CONSULTINVOICE";
export const OPEN_CONSULTINVOICE_FORM = "OPEN_CONSULTINVOICE_FORM";
export const CLOSE_CONSULTINVOICE_FORM = "CLOSE_CONSULTINVOICE_FORM";
export const EDIT_SELECTED_CONSULTINVOICE = "EDIT_SELECTED_CONSULTINVOICE";
export const CONSULTINVOICE_FORM_TOOGLE_LOADING = "CONSULTINVOICE_FORM_TOOGLE_LOADING";
/* <---- CONSULTINVOICE ----> */

/* CONSULTINVOICEPLANNING */
export const SET_CONSULTINVOICEPLANNING_LIST = "SET_CONSULTINVOICEPLANNING_LIST";
export const ADD_NEW_CONSULTINVOICEPLANNING = "ADD_NEW_CONSULTINVOICEPLANNING";
export const UPDATE_CONSULTINVOICEPLANNING = "UPDATE_CONSULTINVOICEPLANNING";
export const DELETE_CONSULTINVOICEPLANNING = "DELETE_CONSULTINVOICEPLANNING";
export const OPEN_CONSULTINVOICEPLANNING_FORM = "OPEN_CONSULTINVOICEPLANNING_FORM";
export const CLOSE_CONSULTINVOICEPLANNING_FORM = "CLOSE_CONSULTINVOICEPLANNING_FORM";
export const EDIT_SELECTED_CONSULTINVOICEPLANNING = "EDIT_SELECTED_CONSULTINVOICEPLANNING";
export const CONSULTINVOICEPLANNING_FORM_TOOGLE_LOADING = "CONSULTINVOICEPLANNING_FORM_TOOGLE_LOADING";
/* <---- CONSULTINVOICEPLANNING ----> */

/* CONSULTOR */
export const SET_CONSULTOR_LIST = "SET_CONSULTOR_LIST";
export const ADD_NEW_CONSULTOR = "ADD_NEW_CONSULTOR";
export const UPDATE_CONSULTOR = "UPDATE_CONSULTOR";
export const DELETE_CONSULTOR = "DELETE_CONSULTOR";
export const OPEN_CONSULTOR_FORM = "OPEN_CONSULTOR_FORM";
export const CLOSE_CONSULTOR_FORM = "CLOSE_CONSULTOR_FORM";
export const EDIT_SELECTED_CONSULTOR = "EDIT_SELECTED_CONSULTOR";
export const CONSULTOR_FORM_TOOGLE_LOADING = "CONSULTOR_FORM_TOOGLE_LOADING";
/* <---- CONSULTOR ----> */

/* LAB */
export const SET_LAB_LIST = "SET_LAB_LIST";
export const ADD_NEW_LAB = "ADD_NEW_LAB";
export const UPDATE_LAB = "UPDATE_LAB";
export const DELETE_LAB = "DELETE_LAB";
export const OPEN_LAB_FORM = "OPEN_LAB_FORM";
export const CLOSE_LAB_FORM = "CLOSE_LAB_FORM";
export const EDIT_SELECTED_LAB = "EDIT_SELECTED_LAB";
export const LAB_FORM_TOOGLE_LOADING = "LAB_FORM_TOOGLE_LOADING";
/* <---- LAB ----> */

/* SOCIETY */
export const SET_SOCIETY_LIST = "SET_SOCIETY_LIST";
export const ADD_NEW_SOCIETY = "ADD_NEW_SOCIETY";
export const UPDATE_SOCIETY = "UPDATE_SOCIETY";
export const DELETE_SOCIETY = "DELETE_SOCIETY";
export const OPEN_SOCIETY_FORM = "OPEN_SOCIETY_FORM";
export const CLOSE_SOCIETY_FORM = "CLOSE_SOCIETY_FORM";
export const EDIT_SELECTED_SOCIETY = "EDIT_SELECTED_SOCIETY";
export const SOCIETY_FORM_TOOGLE_LOADING = "SOCIETY_FORM_TOOGLE_LOADING";
/* <---- SOCIETY ----> */

/* MAINTENANCE */
export const SET_MAINTENANCE_LIST = "SET_MAINTENANCE_LIST";
export const ADD_NEW_MAINTENANCE = "ADD_NEW_MAINTENANCE";
export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const DELETE_MAINTENANCE = "DELETE_MAINTENANCE";
export const OPEN_MAINTENANCE_FORM = "OPEN_MAINTENANCE_FORM";
export const CLOSE_MAINTENANCE_FORM = "CLOSE_MAINTENANCE_FORM";
export const EDIT_SELECTED_MAINTENANCE = "EDIT_SELECTED_MAINTENANCE";
export const MAINTENANCE_FORM_TOOGLE_LOADING = "MAINTENANCE_FORM_TOOGLE_LOADING";
/* <---- MAINTENANCE ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENT ----> */

/* STOCK */
export const SET_STOCK_LIST = "SET_STOCK_LIST";
export const ADD_NEW_STOCK = "ADD_NEW_STOCK";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const DELETE_STOCK = "DELETE_STOCK";
export const OPEN_STOCK_FORM = "OPEN_STOCK_FORM";
export const CLOSE_STOCK_FORM = "CLOSE_STOCK_FORM";
export const EDIT_SELECTED_STOCK = "EDIT_SELECTED_STOCK";
export const STOCK_FORM_TOOGLE_LOADING = "STOCK_FORM_TOOGLE_LOADING";
/* <---- STOCK ----> */

/* BUSINESSALERT */
export const SET_BUSINESSALERT_LIST = "SET_BUSINESSALERT_LIST";
export const ADD_NEW_BUSINESSALERT = "ADD_NEW_BUSINESSALERT";
export const UPDATE_BUSINESSALERT = "UPDATE_BUSINESSALERT";
export const DELETE_BUSINESSALERT = "DELETE_BUSINESSALERT";
export const OPEN_BUSINESSALERT_FORM = "OPEN_BUSINESSALERT_FORM";
export const CLOSE_BUSINESSALERT_FORM = "CLOSE_BUSINESSALERT_FORM";
export const EDIT_SELECTED_BUSINESSALERT = "EDIT_SELECTED_BUSINESSALERT";
export const BUSINESSALERT_FORM_TOOGLE_LOADING = "BUSINESSALERT_FORM_TOOGLE_LOADING";
/* <---- BUSINESSALERT ----> */

/* COLUMN */
export const SET_COLUMN_LIST = "SET_COLUMN_LIST";
export const ADD_NEW_COLUMN = "ADD_NEW_COLUMN";
export const UPDATE_COLUMN = "UPDATE_COLUMN";
export const DELETE_COLUMN = "DELETE_COLUMN";
export const OPEN_COLUMN_FORM = "OPEN_COLUMN_FORM";
export const CLOSE_COLUMN_FORM = "CLOSE_COLUMN_FORM";
export const EDIT_SELECTED_COLUMN = "EDIT_SELECTED_COLUMN";
export const COLUMN_FORM_TOOGLE_LOADING = "COLUMN_FORM_TOOGLE_LOADING";
/* <---- COLUMN ----> */

/* SAMPLING */
export const SET_SAMPLING_LIST = "SET_SAMPLING_LIST";
export const ADD_NEW_SAMPLING = "ADD_NEW_SAMPLING";
export const UPDATE_SAMPLING = "UPDATE_SAMPLING";
export const DELETE_SAMPLING = "DELETE_SAMPLING";
export const OPEN_SAMPLING_FORM = "OPEN_SAMPLING_FORM";
export const CLOSE_SAMPLING_FORM = "CLOSE_SAMPLING_FORM";
export const EDIT_SELECTED_SAMPLING = "EDIT_SELECTED_SAMPLING";
export const SAMPLING_FORM_TOOGLE_LOADING = "SAMPLING_FORM_TOOGLE_LOADING";
/* <---- SAMPLING ----> */

/* FEE */
export const SET_FEE_LIST = "SET_FEE_LIST";
export const ADD_NEW_FEE = "ADD_NEW_FEE";
export const UPDATE_FEE = "UPDATE_FEE";
export const DELETE_FEE = "DELETE_FEE";
export const OPEN_FEE_FORM = "OPEN_FEE_FORM";
export const CLOSE_FEE_FORM = "CLOSE_FEE_FORM";
export const EDIT_SELECTED_FEE = "EDIT_SELECTED_FEE";
export const FEE_FORM_TOOGLE_LOADING = "FEE_FORM_TOOGLE_LOADING";
/* <---- FEE ----> */

/* MATRIX */
export const SET_MATRIX_LIST = "SET_MATRIX_LIST";
export const ADD_NEW_MATRIX = "ADD_NEW_MATRIX";
export const UPDATE_MATRIX = "UPDATE_MATRIX";
export const DELETE_MATRIX = "DELETE_MATRIX";
export const OPEN_MATRIX_FORM = "OPEN_MATRIX_FORM";
export const CLOSE_MATRIX_FORM = "CLOSE_MATRIX_FORM";
export const EDIT_SELECTED_MATRIX = "EDIT_SELECTED_MATRIX";
export const MATRIX_FORM_TOOGLE_LOADING = "MATRIX_FORM_TOOGLE_LOADING";
/* <---- MATRIX ----> */

/* PLANNING */
export const SET_PLANNING_LIST = "SET_PLANNING_LIST";
export const ADD_NEW_PLANNING = "ADD_NEW_PLANNING";
export const UPDATE_PLANNING = "UPDATE_PLANNING";
export const DELETE_PLANNING = "DELETE_PLANNING";
export const OPEN_PLANNING_FORM = "OPEN_PLANNING_FORM";
export const CLOSE_PLANNING_FORM = "CLOSE_PLANNING_FORM";
export const EDIT_SELECTED_PLANNING = "EDIT_SELECTED_PLANNING";
export const PLANNING_FORM_TOOGLE_LOADING = "PLANNING_FORM_TOOGLE_LOADING";
/* <---- PLANNING ----> */

/* SAMPLINGPOINT */
export const SET_SAMPLINGPOINT_LIST = "SET_SAMPLINGPOINT_LIST";
export const ADD_NEW_SAMPLINGPOINT = "ADD_NEW_SAMPLINGPOINT";
export const UPDATE_SAMPLINGPOINT = "UPDATE_SAMPLINGPOINT";
export const DELETE_SAMPLINGPOINT = "DELETE_SAMPLINGPOINT";
export const OPEN_SAMPLINGPOINT_FORM = "OPEN_SAMPLINGPOINT_FORM";
export const CLOSE_SAMPLINGPOINT_FORM = "CLOSE_SAMPLINGPOINT_FORM";
export const EDIT_SELECTED_SAMPLINGPOINT = "EDIT_SELECTED_SAMPLINGPOINT";
export const SAMPLINGPOINT_FORM_TOOGLE_LOADING = "SAMPLINGPOINT_FORM_TOOGLE_LOADING";
/* <---- SAMPLINGPOINT ----> */

/* SERVICE */
export const SET_SERVICE_LIST = "SET_SERVICE_LIST";
export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const OPEN_SERVICE_FORM = "OPEN_SERVICE_FORM";
export const CLOSE_SERVICE_FORM = "CLOSE_SERVICE_FORM";
export const EDIT_SELECTED_SERVICE = "EDIT_SELECTED_SERVICE";
export const SERVICE_FORM_TOOGLE_LOADING = "SERVICE_FORM_TOOGLE_LOADING";
/* <---- SERVICE ----> */

/* CONTAINER */
export const SET_CONTAINER_LIST = "SET_CONTAINER_LIST";
export const ADD_NEW_CONTAINER = "ADD_NEW_CONTAINER";
export const UPDATE_CONTAINER = "UPDATE_CONTAINER";
export const DELETE_CONTAINER = "DELETE_CONTAINER";
export const OPEN_CONTAINER_FORM = "OPEN_CONTAINER_FORM";
export const CLOSE_CONTAINER_FORM = "CLOSE_CONTAINER_FORM";
export const EDIT_SELECTED_CONTAINER = "EDIT_SELECTED_CONTAINER";
export const CONTAINER_FORM_TOOGLE_LOADING = "CONTAINER_FORM_TOOGLE_LOADING";
/* <---- CONTAINER ----> */

/* LEGISLATION */
export const SET_LEGISLATION_LIST = "SET_LEGISLATION_LIST";
export const ADD_NEW_LEGISLATION = "ADD_NEW_LEGISLATION";
export const UPDATE_LEGISLATION = "UPDATE_LEGISLATION";
export const DELETE_LEGISLATION = "DELETE_LEGISLATION";
export const OPEN_LEGISLATION_FORM = "OPEN_LEGISLATION_FORM";
export const CLOSE_LEGISLATION_FORM = "CLOSE_LEGISLATION_FORM";
export const EDIT_SELECTED_LEGISLATION = "EDIT_SELECTED_LEGISLATION";
export const LEGISLATION_FORM_TOOGLE_LOADING = "LEGISLATION_FORM_TOOGLE_LOADING";
/* <---- LEGISLATION ----> */

/* PARAMETER */
export const SET_PARAMETER_LIST = "SET_PARAMETER_LIST";
export const ADD_NEW_PARAMETER = "ADD_NEW_PARAMETER";
export const UPDATE_PARAMETER = "UPDATE_PARAMETER";
export const DELETE_PARAMETER = "DELETE_PARAMETER";
export const OPEN_PARAMETER_FORM = "OPEN_PARAMETER_FORM";
export const CLOSE_PARAMETER_FORM = "CLOSE_PARAMETER_FORM";
export const EDIT_SELECTED_PARAMETER = "EDIT_SELECTED_PARAMETER";
export const PARAMETER_FORM_TOOGLE_LOADING = "PARAMETER_FORM_TOOGLE_LOADING";
/* <---- PARAMETER ----> */

/* LOCATION */
export const SET_LOCATION_LIST = "SET_LOCATION_LIST";
export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const OPEN_LOCATION_FORM = "OPEN_LOCATION_FORM";
export const CLOSE_LOCATION_FORM = "CLOSE_LOCATION_FORM";
export const EDIT_SELECTED_LOCATION = "EDIT_SELECTED_LOCATION";
export const LOCATION_FORM_TOOGLE_LOADING = "LOCATION_FORM_TOOGLE_LOADING";
/* <---- LOCATION ----> */

/* UNITY */
export const SET_UNITY_LIST = "SET_UNITY_LIST";
export const ADD_NEW_UNITY = "ADD_NEW_UNITY";
export const UPDATE_UNITY = "UPDATE_UNITY";
export const DELETE_UNITY = "DELETE_UNITY";
export const OPEN_UNITY_FORM = "OPEN_UNITY_FORM";
export const CLOSE_UNITY_FORM = "CLOSE_UNITY_FORM";
export const EDIT_SELECTED_UNITY = "EDIT_SELECTED_UNITY";
export const UNITY_FORM_TOOGLE_LOADING = "UNITY_FORM_TOOGLE_LOADING";
/* <---- UNITY ----> */

/* SUGGESTION */
export const SET_SUGGESTION_LIST = "SET_SUGGESTION_LIST";
export const ADD_NEW_SUGGESTION = "ADD_NEW_SUGGESTION";
export const UPDATE_SUGGESTION = "UPDATE_SUGGESTION";
export const DELETE_SUGGESTION = "DELETE_SUGGESTION";
export const OPEN_SUGGESTION_FORM = "OPEN_SUGGESTION_FORM";
export const CLOSE_SUGGESTION_FORM = "CLOSE_SUGGESTION_FORM";
export const EDIT_SELECTED_SUGGESTION = "EDIT_SELECTED_SUGGESTION";
export const SUGGESTION_FORM_TOOGLE_LOADING = "SUGGESTION_FORM_TOOGLE_LOADING";
/* <---- SUGGESTION ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT_LIST = "SET_ESTABLISHMENT_LIST";
export const ADD_NEW_ESTABLISHMENT = "ADD_NEW_ESTABLISHMENT";
export const UPDATE_ESTABLISHMENT = "UPDATE_ESTABLISHMENT";
export const DELETE_ESTABLISHMENT = "DELETE_ESTABLISHMENT";
export const OPEN_ESTABLISHMENT_FORM = "OPEN_ESTABLISHMENT_FORM";
export const CLOSE_ESTABLISHMENT_FORM = "CLOSE_ESTABLISHMENT_FORM";
export const EDIT_SELECTED_ESTABLISHMENT = "EDIT_SELECTED_ESTABLISHMENT";
export const ESTABLISHMENT_FORM_TOOGLE_LOADING = "ESTABLISHMENT_FORM_TOOGLE_LOADING";
/* <---- ESTABLISHMENT ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION_LIST = "SET_CONFIGURATION_LIST";
export const ADD_NEW_CONFIGURATION = "ADD_NEW_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const OPEN_CONFIGURATION_FORM = "OPEN_CONFIGURATION_FORM";
export const CLOSE_CONFIGURATION_FORM = "CLOSE_CONFIGURATION_FORM";
export const EDIT_SELECTED_CONFIGURATION = "EDIT_SELECTED_CONFIGURATION";
export const CONFIGURATION_FORM_TOOGLE_LOADING = "CONFIGURATION_FORM_TOOGLE_LOADING";
/* <---- CONFIGURATION ----> */

/* HOUR */
export const SET_HOUR_LIST = "SET_HOUR_LIST";
export const ADD_NEW_HOUR = "ADD_NEW_HOUR";
export const UPDATE_HOUR = "UPDATE_HOUR";
export const DELETE_HOUR = "DELETE_HOUR";
export const OPEN_HOUR_FORM = "OPEN_HOUR_FORM";
export const CLOSE_HOUR_FORM = "CLOSE_HOUR_FORM";
export const EDIT_SELECTED_HOUR = "EDIT_SELECTED_HOUR";
export const HOUR_FORM_TOOGLE_LOADING = "HOUR_FORM_TOOGLE_LOADING";
/* <---- HOUR ----> */

/* ACTIVITY */
export const SET_ACTIVITY_LIST = "SET_ACTIVITY_LIST";
export const ADD_NEW_ACTIVITY = "ADD_NEW_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const OPEN_ACTIVITY_FORM = "OPEN_ACTIVITY_FORM";
export const CLOSE_ACTIVITY_FORM = "CLOSE_ACTIVITY_FORM";
export const EDIT_SELECTED_ACTIVITY = "EDIT_SELECTED_ACTIVITY";
export const ACTIVITY_FORM_TOOGLE_LOADING = "ACTIVITY_FORM_TOOGLE_LOADING";
/* <---- ACTIVITY ----> */

/* PROJECT */
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const OPEN_PROJECT_FORM = "OPEN_PROJECT_FORM";
export const CLOSE_PROJECT_FORM = "CLOSE_PROJECT_FORM";
export const EDIT_SELECTED_PROJECT = "EDIT_SELECTED_PROJECT";
export const PROJECT_FORM_TOOGLE_LOADING = "PROJECT_FORM_TOOGLE_LOADING";
/* <---- PROJECT ----> */

/* BUSINESS */
export const SET_BUSINESS_LIST = "SET_BUSINESS_LIST";
export const ADD_NEW_BUSINESS = "ADD_NEW_BUSINESS";
export const UPDATE_BUSINESS = "UPDATE_BUSINESS";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const OPEN_BUSINESS_FORM = "OPEN_BUSINESS_FORM";
export const CLOSE_BUSINESS_FORM = "CLOSE_BUSINESS_FORM";
export const EDIT_SELECTED_BUSINESS = "EDIT_SELECTED_BUSINESS";
export const BUSINESS_FORM_TOOGLE_LOADING = "BUSINESS_FORM_TOOGLE_LOADING";
/* <---- BUSINESS ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */


/* TIMEREQUEST */
export const SET_TIMEREQUEST_LIST = "SET_TIMEREQUEST_LIST";
export const ADD_NEW_TIMEREQUEST = "ADD_NEW_TIMEREQUEST";
export const UPDATE_TIMEREQUEST = "UPDATE_TIMEREQUEST";
export const DELETE_TIMEREQUEST = "DELETE_TIMEREQUEST";
export const OPEN_TIMEREQUEST_FORM = "OPEN_TIMEREQUEST_FORM";
export const CLOSE_TIMEREQUEST_FORM = "CLOSE_TIMEREQUEST_FORM";
export const EDIT_SELECTED_TIMEREQUEST = "EDIT_SELECTED_TIMEREQUEST";
export const TIMEREQUEST_FORM_TOOGLE_LOADING =
  "TIMEREQUEST_FORM_TOOGLE_LOADING";
/* <---- TIMEREQUEST ----> */


/* ESTABLISHMENTINFO */
export const SET_ESTABLISHMENTINFO = "SET_ESTABLISHMENTINFO";
export const OPEN_ESTABLISHMENTINFO_FORM = "OPEN_ESTABLISHMENTINFO_FORM";
export const CLOSE_ESTABLISHMENTINFO_FORM = "CLOSE_ESTABLISHMENTINFO_FORM";
export const EDIT_SELECTED_ESTABLISHMENTINFO =
  "EDIT_SELECTED_ESTABLISHMENTINFO";
export const ESTABLISHMENTINFO_FORM_TOOGLE_LOADING =
  "ESTABLISHMENTINFO_FORM_TOOGLE_LOADING";
/* <---- ESTABLISHMENTINFO ----> */

/* HOLIDAYREQUEST */
export const SET_HOLIDAYREQUEST_LIST = "SET_HOLIDAYREQUEST_LIST";
export const ADD_NEW_HOLIDAYREQUEST = "ADD_NEW_HOLIDAYREQUEST";
export const UPDATE_HOLIDAYREQUEST = "UPDATE_HOLIDAYREQUEST";
export const DELETE_HOLIDAYREQUEST = "DELETE_HOLIDAYREQUEST";
export const OPEN_HOLIDAYREQUEST_FORM = "OPEN_HOLIDAYREQUEST_FORM";
export const CLOSE_HOLIDAYREQUEST_FORM = "CLOSE_HOLIDAYREQUEST_FORM";
export const EDIT_SELECTED_HOLIDAYREQUEST = "EDIT_SELECTED_HOLIDAYREQUEST";
export const HOLIDAYREQUEST_FORM_TOOGLE_LOADING =
  "HOLIDAYREQUEST_FORM_TOOGLE_LOADING";
/* <---- HOLIDAYREQUEST ----> */

/* SINGUP */
export const SET_SINGUP_LIST = "SET_SINGUP_LIST";
export const ADD_NEW_SINGUP = "ADD_NEW_SINGUP";
export const UPDATE_SINGUP = "UPDATE_SINGUP";
export const DELETE_SINGUP = "DELETE_SINGUP";
export const OPEN_SINGUP_FORM = "OPEN_SINGUP_FORM";
export const CLOSE_SINGUP_FORM = "CLOSE_SINGUP_FORM";
export const EDIT_SELECTED_SINGUP = "EDIT_SELECTED_SINGUP";
export const SINGUP_FORM_TOOGLE_LOADING = "SINGUP_FORM_TOOGLE_LOADING";
/* <---- SINGUP ----> */

/* LEAVE */
export const SET_LEAVE_LIST = "SET_LEAVE_LIST";
export const ADD_NEW_LEAVE = "ADD_NEW_LEAVE";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const DELETE_LEAVE = "DELETE_LEAVE";
export const OPEN_LEAVE_FORM = "OPEN_LEAVE_FORM";
export const CLOSE_LEAVE_FORM = "CLOSE_LEAVE_FORM";
export const EDIT_SELECTED_LEAVE = "EDIT_SELECTED_LEAVE";
export const LEAVE_FORM_TOOGLE_LOADING = "LEAVE_FORM_TOOGLE_LOADING";
/* <---- LEAVE ----> */
