import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_LOGSACCESS,
    DELETE_LOGSACCESS,
    LOGSACCESS_FORM_TOOGLE_LOADING,
    SET_LOGSACCESS_LIST,
    SHOW_NOTIFICATION,
    UPDATE_LOGSACCESS,
} from "../constants";
import { formatLogsAcces } from "./settingsActionsUtils";

/* LOGSACCESS LIST */
export const fetchLogsAcces = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.logsAcces.list)
            .then((response) => {
                const logsAcces = formatLogsAcces(response.data);
                dispatch({
                    type: SET_LOGSACCESS_LIST,
                    payload: keyBy(logsAcces, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// LOGSACCES
export const createLogsAccess = (newLogsAccess) => {
    return async (dispatch) => {
        dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.logsAcces.create, newLogsAccess)
            .then((response) => {
                const logsAccess = formatLogsAcces(response.data);
                dispatch({ type: ADD_NEW_LOGSACCESS, payload: logsAccess });
                dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Acceso creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateLogsAccess = (updatedLogsAccess) => {
    return async (dispatch) => {
        dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.logsAcces.edit}${updatedLogsAccess && updatedLogsAccess._id}`, updatedLogsAccess)
            .then((response) => {
                const logsAccess = formatLogsAcces(response.data);
                dispatch({ type: UPDATE_LOGSACCESS, payload: logsAccess });
                dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Acceso actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteLogsAcces = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.logsAcces.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_LOGSACCESS, payload: Ids });
                dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Acceso eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LOGSACCESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
