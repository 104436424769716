import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_OTHERADITIONALINFORMATION,
    DELETE_OTHERADITIONALINFORMATION,
    OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING,
    SET_OTHERADITIONALINFORMATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_OTHERADITIONALINFORMATION,
} from "../constants";
import { formatOtherAditionalInformations } from "./settingsActionsUtils";

/* OTHERADITIONALINFORMATION LIST */
export const fetchOtherAditionalInformations = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.otherAditionalInformations.list)
            .then((response) => {
                const otherAditionalInformations = formatOtherAditionalInformations(response.data);
                dispatch({
                    type: SET_OTHERADITIONALINFORMATION_LIST,
                    payload: keyBy(otherAditionalInformations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// OTHERADITIONALINFORMATIONS
export const createOtherAditionalInformation = (newOtherAditionalInformation) => {
    return async (dispatch) => {
        dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.otherAditionalInformations.create, newOtherAditionalInformation)
            .then((response) => {
                const otherAditionalInformation = formatOtherAditionalInformations(response.data);
                dispatch({ type: ADD_NEW_OTHERADITIONALINFORMATION, payload: otherAditionalInformation });
                dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "OtraInformacionAdicional creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateOtherAditionalInformation = (updatedOtherAditionalInformation) => {
    return async (dispatch) => {
        dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.otherAditionalInformations.edit}${updatedOtherAditionalInformation && updatedOtherAditionalInformation._id}`, updatedOtherAditionalInformation)
            .then((response) => {
                const otherAditionalInformation = formatOtherAditionalInformations(response.data);
                dispatch({ type: UPDATE_OTHERADITIONALINFORMATION, payload: otherAditionalInformation });
                dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "OtraInformacionAdicional actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteOtherAditionalInformations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.otherAditionalInformations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_OTHERADITIONALINFORMATION, payload: Ids });
                dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "OtraInformacionAdicional eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
