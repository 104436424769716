import { OrderedListOutlined } from "@ant-design/icons";
import { UnorderedListOutlined } from "@ant-design/icons";
import {
  AlertOutlined, AppstoreOutlined,
  AuditOutlined, BankOutlined, BookOutlined,
  BoxPlotOutlined,
  BuildOutlined,
  BulbOutlined, CalendarOutlined, CheckOutlined, ClockCircleOutlined, EnvironmentOutlined, EuroCircleOutlined, ExperimentOutlined, FileExcelOutlined, FileTextOutlined, FundProjectionScreenOutlined, HeatMapOutlined, HomeOutlined, InteractionOutlined, LoginOutlined,
  LogoutOutlined, ProductOutlined, PushpinOutlined, RobotOutlined, ScheduleOutlined,
  SettingOutlined, ShopOutlined, TableOutlined, TeamOutlined,
  ToolOutlined, UsergroupAddOutlined, UserOutlined, UserSwitchOutlined
} from "@ant-design/icons";
import React from "react";
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <HomeOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },


  equipments: {
    key: "equipments",
    slug: "equipments",
    to: "/equipments",
    title: "Equipos",
    icon: <RobotOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "gmao",
  },

  holidayRequest: {
    key: "holidayRequest",
    slug: "holidayRequest",
    to: "/holidayRequest",
    title: "Vacaciones",
    icon: <CalendarOutlined></CalendarOutlined>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "rrhh",
  },

  mySingUp: {
    key: "mySingUp",
    slug: "mySingUp",
    to: "/mySingUp",
    title: "Mis Horarios",
    icon: <ScheduleOutlined></ScheduleOutlined>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "rrhh",
  },
  singUps: {
    key: "singUps",
    slug: "singUps",
    to: "/singUps",
    title: "Fichajes",
    icon: <TableOutlined></TableOutlined>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "rrhh",
  },

  timerequest: {
    key: "timerequest",
    slug: "timerequest",
    to: "/timerequest",
    title: "Solicitudes",
    icon: <CalendarOutlined></CalendarOutlined>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "rrhh",
  },

  leaves: {
    key: "leaves",
    slug: "leaves",
    to: "/leaves",
    title: "Bajas",
    icon: <UserSwitchOutlined></UserSwitchOutlined>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "rrhh",
  },

  singUp: {
    key: "singUp",
    slug: "singUp",
    to: "/singUp",
    title: "Fichar",
    icon: <CheckOutlined></CheckOutlined>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  hours: {
    key: "hours",
    slug: "hours",
    to: "/hours",
    title: "Horas imputadas",
    icon: <ClockCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  stocks: {
    key: "stocks",
    slug: "stocks",
    to: "/stocks",
    title: "Stocks",
    icon: <ProductOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  projects: {
    key: "projects",
    slug: "projects",
    to: "/projects",
    title: "Proyectos",
    icon: <FundProjectionScreenOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "rrhh",
  },
  calendar: {
    key: "calendar",
    slug: "calendar",
    to: "/calendar",
    title: "Calendario",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "gmao",
  },
  gmao: {
    key: "gmao",
    slug: "gmao",
    to: "/gmao",
    title: "GMAO",
    icon: <ToolOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  activitys: {
    key: "activitys",
    slug: "activitys",
    to: "/activitys",
    title: "Actividades",
    icon: <InteractionOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "gmao",
  },
  businessControl: {
    key: "businessControl",
    slug: "businessControl",
    to: "/businessControl",
    title: "Gestión clientes",
    icon: <AlertOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  societys: {
    key: "societys",
    slug: "societys",
    to: "/societys",
    title: "Datos Fiscales",
    icon: <BankOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "businessControl",
  },
  businesss: {
    key: "businesss",
    slug: "businesss",
    to: "/businesss",
    title: "Clientes",
    icon: <UsergroupAddOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "businessControl",
  },

  establishments: {
    key: "establishments",
    slug: "establishments",
    to: "/establishments",
    title: "Establecimientos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "businessControl",
  },
  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  unitys: {
    key: "unitys",
    slug: "unitys",
    to: "/unitys",
    title: "Unidades",
    icon: <BuildOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "masters",
  },
  matrixs: {
    key: "matrixs",
    slug: "matrixs",
    to: "/matrixs",
    title: "Matrices",
    icon: <HeatMapOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "masters",
  },
  locations: {
    key: "locations",
    slug: "locations",
    to: "/locations",
    title: "Localizaciones",
    icon: <EnvironmentOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "masters",
  },
  parameters: {
    key: "parameters",
    slug: "parameters",
    to: "/parameters",
    title: "Parámetros",
    icon: <ToolOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "planning",
  },
  legislations: {
    key: "legislations",
    slug: "legislations",
    to: "/legislations",
    title: "Legislaciones",
    icon: <AuditOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "masters",
  },
  containers: {
    key: "containers",
    slug: "containers",
    to: "/containers",
    title: "Envases",
    icon: <BoxPlotOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "masters",
  },
  services: {
    key: "services",
    slug: "services",
    to: "/services",
    title: "Servicios",
    icon: <AppstoreOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "planning",
  },
  samplingpoints: {
    key: "samplingpoints",
    slug: "samplingpoints",
    to: "/samplingpoints",
    title: "Punto de muestreos",
    icon: <PushpinOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "planning",
  },
  plannings: {
    key: "plannings",
    slug: "plannings",
    to: "/plannings",
    title: "Planificaciones",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "planning",
  },
  planning: {
    key: "planning",
    slug: "planning",
    to: "/planning",
    title: "Datos técnicos",
    icon: <ExperimentOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  rrhh: {
    key: "rrhh",
    slug: "rrhh",
    to: "/rrhh",
    title: "RRHH",
    icon: <TeamOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  masters: {
    key: "masters",
    slug: "masters",
    to: "/masters",
    title: "Maestros",
    icon: <BookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  billings: {
    key: "billings",
    slug: "billings",
    to: "/billings",
    title: "Facturación",
    icon: <ShopOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  suggestions: {
    key: "suggestions",
    slug: "suggestions",
    to: "/suggestions",
    title: "Sugerencias",
    icon: <BulbOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "masters",
  },
  reports: {
    key: "reports",
    slug: "reports",
    to: "/reports",
    title: "Informes",
    icon: <FileExcelOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  configurations: {
    key: "configurations",
    slug: "configurations",
    to: "/configurations",
    title: "Configuración",
    icon: <SettingOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  fees: {
    key: "fees",
    slug: "fees",
    to: "/fees",
    title: "Tarifas",
    icon: <EuroCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "billings",
  },
  samplings: {
    key: "samplings",
    slug: "samplings",
    to: "/samplings",
    title: "Muestras",
    icon: <ExperimentOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "planning",
  },
  microbiological: {
    key: "microbiological",
    slug: "microbiological",
    to: "/microbiological",
    title: "Micro",
    icon: <ExperimentOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "planning",
  },
  columns: {
    key: "columns",
    slug: "columns",
    to: "/columns",
    title: "Columnas",
    icon: <TableOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "masters",
  },
  businessAlerts: {
    key: "businessAlerts",
    slug: "businessAlerts",
    to: "/businessAlerts",
    title: "Alertas",
    icon: <AlertOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
    parent: "masters",
  },
  maintenances: {
    key: "maintenances",
    slug: "maintenances",
    to: "/maintenances",
    title: "Mantenimientos",
    icon: <ToolOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "gmao",
  },
  labs: {
    key: "labs",
    slug: "labs",
    to: "/labs",
    title: "Facturas lab",
    icon: <BankOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "billings",
  },
  consultInvoicePlannings: {
    key: "consultInvoicePlannings",
    slug: "consultInvoicePlannings",
    to: "/consultInvoicePlannings",
    title: "Planif. Facturas Consult.",
    icon: <ScheduleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "billings",
  },
  consultInvoices: {
    key: "consultInvoices",
    slug: "consultInvoices",
    to: "/consultInvoices",
    title: "Facturas consult.",
    icon: <FileTextOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "billings",
  },
  roles: {
    key: "roles",
    slug: "roles",
    to: "/roles",
    title: "Roles",
    icon: <UserSwitchOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "masters",
  },
  ivas: {
    key: "ivas",
    slug: "ivas",
    to: "/ivas",
    title: "IVA",
    icon: <EuroCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "masters",
  },
  logsAcces: {
    key: "logsAcces",
    slug: "logsAcces",
    to: "/logsAcces",
    title: "Accesos",
    icon: <ClockCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "masters",
  },
  aditionalInformations: {
    key: "aditionalInformations",
    slug: "aditionalInformations",
    to: "/aditionalInformations",
    title: "Información Adicional Servicios",
    icon: <UnorderedListOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "masters",
  },
  otherAditionalInformations: {
    key: "otherAditionalInformations",
    slug: "otherAditionalInformations",
    to: "/otherAditionalInformations",
    title: "Otra Informacion Adicional Muestras",
    icon: <OrderedListOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "masters",
  },
  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
};
