import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  THEME,
  SET_SINGUP_LIST,
  ADD_NEW_SINGUP,
  UPDATE_SINGUP,
  DELETE_SINGUP,
  OPEN_SINGUP_FORM,
  CLOSE_SINGUP_FORM,
  EDIT_SELECTED_SINGUP,
  SINGUP_FORM_TOOGLE_LOADING,
  ADD_NEW_TIMEREQUEST,
  SET_TIMEREQUEST_LIST,
  UPDATE_TIMEREQUEST,
  DELETE_TIMEREQUEST,
  OPEN_TIMEREQUEST_FORM,
  CLOSE_TIMEREQUEST_FORM,
  EDIT_SELECTED_TIMEREQUEST,
  TIMEREQUEST_FORM_TOOGLE_LOADING,
  SET_HOLIDAYREQUEST_LIST,
  ADD_NEW_HOLIDAYREQUEST,
  UPDATE_HOLIDAYREQUEST,
  DELETE_HOLIDAYREQUEST,
  OPEN_HOLIDAYREQUEST_FORM,
  CLOSE_HOLIDAYREQUEST_FORM,
  EDIT_SELECTED_HOLIDAYREQUEST,
  HOLIDAYREQUEST_FORM_TOOGLE_LOADING,
  SET_LEAVE_LIST,
  ADD_NEW_LEAVE,
  UPDATE_LEAVE,
  DELETE_LEAVE,
  OPEN_LEAVE_FORM,
  CLOSE_LEAVE_FORM,
  EDIT_SELECTED_LEAVE,
  LEAVE_FORM_TOOGLE_LOADING,
  SET_ESTABLISHMENTINFO,
  OPEN_ESTABLISHMENTINFO_FORM,
  CLOSE_ESTABLISHMENTINFO_FORM,
  EDIT_SELECTED_ESTABLISHMENTINFO,
  ESTABLISHMENTINFO_FORM_TOOGLE_LOADING,
  ADD_NEW_BUSINESS,
  SET_BUSINESS_LIST,
  UPDATE_BUSINESS,
  DELETE_BUSINESS,
  OPEN_BUSINESS_FORM,
  CLOSE_BUSINESS_FORM,
  EDIT_SELECTED_BUSINESS,
  BUSINESS_FORM_TOOGLE_LOADING,
  ADD_NEW_PROJECT,
  SET_PROJECT_LIST,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  OPEN_PROJECT_FORM,
  CLOSE_PROJECT_FORM,
  EDIT_SELECTED_PROJECT,
  PROJECT_FORM_TOOGLE_LOADING,
  ADD_NEW_ACTIVITY,
  SET_ACTIVITY_LIST,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
  OPEN_ACTIVITY_FORM,
  CLOSE_ACTIVITY_FORM,
  EDIT_SELECTED_ACTIVITY,
  ACTIVITY_FORM_TOOGLE_LOADING,
  ADD_NEW_HOUR,
  SET_HOUR_LIST,
  UPDATE_HOUR,
  DELETE_HOUR,
  OPEN_HOUR_FORM,
  CLOSE_HOUR_FORM,
  EDIT_SELECTED_HOUR,
  HOUR_FORM_TOOGLE_LOADING,
  ADD_NEW_CONFIGURATION,
  SET_CONFIGURATION_LIST,
  UPDATE_CONFIGURATION,
  DELETE_CONFIGURATION,
  OPEN_CONFIGURATION_FORM,
  CLOSE_CONFIGURATION_FORM,
  EDIT_SELECTED_CONFIGURATION,
  CONFIGURATION_FORM_TOOGLE_LOADING,
  ADD_NEW_ESTABLISHMENT,
  SET_ESTABLISHMENT_LIST,
  UPDATE_ESTABLISHMENT,
  DELETE_ESTABLISHMENT,
  OPEN_ESTABLISHMENT_FORM,
  CLOSE_ESTABLISHMENT_FORM,
  EDIT_SELECTED_ESTABLISHMENT,
  ESTABLISHMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_SUGGESTION,
  SET_SUGGESTION_LIST,
  UPDATE_SUGGESTION,
  DELETE_SUGGESTION,
  OPEN_SUGGESTION_FORM,
  CLOSE_SUGGESTION_FORM,
  EDIT_SELECTED_SUGGESTION,
  SUGGESTION_FORM_TOOGLE_LOADING,
  ADD_NEW_UNITY,
  SET_UNITY_LIST,
  UPDATE_UNITY,
  DELETE_UNITY,
  OPEN_UNITY_FORM,
  CLOSE_UNITY_FORM,
  EDIT_SELECTED_UNITY,
  UNITY_FORM_TOOGLE_LOADING,
  ADD_NEW_LOCATION,
  SET_LOCATION_LIST,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  OPEN_LOCATION_FORM,
  CLOSE_LOCATION_FORM,
  EDIT_SELECTED_LOCATION,
  LOCATION_FORM_TOOGLE_LOADING,
  ADD_NEW_PARAMETER,
  SET_PARAMETER_LIST,
  UPDATE_PARAMETER,
  DELETE_PARAMETER,
  OPEN_PARAMETER_FORM,
  CLOSE_PARAMETER_FORM,
  EDIT_SELECTED_PARAMETER,
  PARAMETER_FORM_TOOGLE_LOADING,
  ADD_NEW_LEGISLATION,
  SET_LEGISLATION_LIST,
  UPDATE_LEGISLATION,
  DELETE_LEGISLATION,
  OPEN_LEGISLATION_FORM,
  CLOSE_LEGISLATION_FORM,
  EDIT_SELECTED_LEGISLATION,
  LEGISLATION_FORM_TOOGLE_LOADING,
  ADD_NEW_CONTAINER,
  SET_CONTAINER_LIST,
  UPDATE_CONTAINER,
  DELETE_CONTAINER,
  OPEN_CONTAINER_FORM,
  CLOSE_CONTAINER_FORM,
  EDIT_SELECTED_CONTAINER,
  CONTAINER_FORM_TOOGLE_LOADING,
  ADD_NEW_SERVICE,
  SET_SERVICE_LIST,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  OPEN_SERVICE_FORM,
  CLOSE_SERVICE_FORM,
  EDIT_SELECTED_SERVICE,
  SERVICE_FORM_TOOGLE_LOADING,
  ADD_NEW_SAMPLINGPOINT,
  SET_SAMPLINGPOINT_LIST,
  UPDATE_SAMPLINGPOINT,
  DELETE_SAMPLINGPOINT,
  OPEN_SAMPLINGPOINT_FORM,
  CLOSE_SAMPLINGPOINT_FORM,
  EDIT_SELECTED_SAMPLINGPOINT,
  SAMPLINGPOINT_FORM_TOOGLE_LOADING,
  ADD_NEW_PLANNING,
  SET_PLANNING_LIST,
  UPDATE_PLANNING,
  DELETE_PLANNING,
  OPEN_PLANNING_FORM,
  CLOSE_PLANNING_FORM,
  EDIT_SELECTED_PLANNING,
  PLANNING_FORM_TOOGLE_LOADING,
  ADD_NEW_MATRIX,
  SET_MATRIX_LIST,
  UPDATE_MATRIX,
  DELETE_MATRIX,
  OPEN_MATRIX_FORM,
  CLOSE_MATRIX_FORM,
  EDIT_SELECTED_MATRIX,
  MATRIX_FORM_TOOGLE_LOADING,
  ADD_NEW_FEE,
  SET_FEE_LIST,
  UPDATE_FEE,
  DELETE_FEE,
  OPEN_FEE_FORM,
  CLOSE_FEE_FORM,
  EDIT_SELECTED_FEE,
  FEE_FORM_TOOGLE_LOADING,
  ADD_NEW_SAMPLING,
  SET_SAMPLING_LIST,
  UPDATE_SAMPLING,
  DELETE_SAMPLING,
  OPEN_SAMPLING_FORM,
  CLOSE_SAMPLING_FORM,
  EDIT_SELECTED_SAMPLING,
  SAMPLING_FORM_TOOGLE_LOADING,
  ADD_NEW_COLUMN,
  SET_COLUMN_LIST,
  UPDATE_COLUMN,
  DELETE_COLUMN,
  OPEN_COLUMN_FORM,
  CLOSE_COLUMN_FORM,
  EDIT_SELECTED_COLUMN,
  COLUMN_FORM_TOOGLE_LOADING,
  ADD_NEW_BUSINESSALERT,
  SET_BUSINESSALERT_LIST,
  UPDATE_BUSINESSALERT,
  DELETE_BUSINESSALERT,
  OPEN_BUSINESSALERT_FORM,
  CLOSE_BUSINESSALERT_FORM,
  EDIT_SELECTED_BUSINESSALERT,
  BUSINESSALERT_FORM_TOOGLE_LOADING,
  ADD_NEW_STOCK,
  SET_STOCK_LIST,
  UPDATE_STOCK,
  DELETE_STOCK,
  OPEN_STOCK_FORM,
  CLOSE_STOCK_FORM,
  EDIT_SELECTED_STOCK,
  STOCK_FORM_TOOGLE_LOADING,
  ADD_NEW_EQUIPMENT,
  SET_EQUIPMENT_LIST,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  OPEN_EQUIPMENT_FORM,
  CLOSE_EQUIPMENT_FORM,
  EDIT_SELECTED_EQUIPMENT,
  EQUIPMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_MAINTENANCE,
  SET_MAINTENANCE_LIST,
  UPDATE_MAINTENANCE,
  DELETE_MAINTENANCE,
  OPEN_MAINTENANCE_FORM,
  CLOSE_MAINTENANCE_FORM,
  EDIT_SELECTED_MAINTENANCE,
  MAINTENANCE_FORM_TOOGLE_LOADING,
  ADD_NEW_SOCIETY,
  SET_SOCIETY_LIST,
  UPDATE_SOCIETY,
  DELETE_SOCIETY,
  OPEN_SOCIETY_FORM,
  CLOSE_SOCIETY_FORM,
  EDIT_SELECTED_SOCIETY,
  SOCIETY_FORM_TOOGLE_LOADING,
  ADD_NEW_LAB,
  SET_LAB_LIST,
  UPDATE_LAB,
  DELETE_LAB,
  OPEN_LAB_FORM,
  CLOSE_LAB_FORM,
  EDIT_SELECTED_LAB,
  LAB_FORM_TOOGLE_LOADING,
  ADD_NEW_CONSULTOR,
  SET_CONSULTOR_LIST,
  UPDATE_CONSULTOR,
  DELETE_CONSULTOR,
  OPEN_CONSULTOR_FORM,
  CLOSE_CONSULTOR_FORM,
  EDIT_SELECTED_CONSULTOR,
  CONSULTOR_FORM_TOOGLE_LOADING,
  ADD_NEW_CONSULTINVOICEPLANNING,
  SET_CONSULTINVOICEPLANNING_LIST,
  UPDATE_CONSULTINVOICEPLANNING,
  DELETE_CONSULTINVOICEPLANNING,
  OPEN_CONSULTINVOICEPLANNING_FORM,
  CLOSE_CONSULTINVOICEPLANNING_FORM,
  EDIT_SELECTED_CONSULTINVOICEPLANNING,
  CONSULTINVOICEPLANNING_FORM_TOOGLE_LOADING,
  ADD_NEW_CONSULTINVOICE,
  SET_CONSULTINVOICE_LIST,
  UPDATE_CONSULTINVOICE,
  DELETE_CONSULTINVOICE,
  OPEN_CONSULTINVOICE_FORM,
  CLOSE_CONSULTINVOICE_FORM,
  EDIT_SELECTED_CONSULTINVOICE,
  CONSULTINVOICE_FORM_TOOGLE_LOADING,
  ADD_NEW_ROLE,
  SET_ROLE_LIST,
  UPDATE_ROLE,
  DELETE_ROLE,
  OPEN_ROLE_FORM,
  CLOSE_ROLE_FORM,
  EDIT_SELECTED_ROLE,
  ROLE_FORM_TOOGLE_LOADING,
  ADD_NEW_IVA,
  SET_IVA_LIST,
  UPDATE_IVA,
  DELETE_IVA,
  OPEN_IVA_FORM,
  CLOSE_IVA_FORM,
  EDIT_SELECTED_IVA,
  IVA_FORM_TOOGLE_LOADING,
  ADD_NEW_ADITIONALINFORMATION,
  SET_ADITIONALINFORMATION_LIST,
  UPDATE_ADITIONALINFORMATION,
  DELETE_ADITIONALINFORMATION,
  OPEN_ADITIONALINFORMATION_FORM,
  CLOSE_ADITIONALINFORMATION_FORM,
  EDIT_SELECTED_ADITIONALINFORMATION,
  ADITIONALINFORMATION_FORM_TOOGLE_LOADING,
  ADD_NEW_OTHERADITIONALINFORMATION,
  SET_OTHERADITIONALINFORMATION_LIST,
  UPDATE_OTHERADITIONALINFORMATION,
  DELETE_OTHERADITIONALINFORMATION,
  OPEN_OTHERADITIONALINFORMATION_FORM,
  CLOSE_OTHERADITIONALINFORMATION_FORM,
  EDIT_SELECTED_OTHERADITIONALINFORMATION,
  OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING,
  ADD_NEW_LOGSACCESS,
  SET_LOGSACCESS_LIST,
  UPDATE_LOGSACCESS,
  DELETE_LOGSACCESS,
  OPEN_LOGSACCESS_FORM,
  CLOSE_LOGSACCESS_FORM,
  EDIT_SELECTED_LOGSACCESS,
  LOGSACCESS_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SINGUPS
export const singUps = handleActions(
  {
    [SET_SINGUP_LIST]: (state, action) => action.payload,
    [ADD_NEW_SINGUP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SINGUP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SINGUP]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const singUpsForm = handleActions(
  {
    [OPEN_SINGUP_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SINGUP_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SINGUP]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SINGUP_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TIMEREQUESTS
export const timeRequests = handleActions(
  {
    [SET_TIMEREQUEST_LIST]: (state, action) => action.payload,
    [ADD_NEW_TIMEREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TIMEREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TIMEREQUEST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const timeRequestsForm = handleActions(
  {
    [OPEN_TIMEREQUEST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TIMEREQUEST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TIMEREQUEST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TIMEREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// HOLIDAYREQUESTS
export const holidayRequests = handleActions(
  {
    [SET_HOLIDAYREQUEST_LIST]: (state, action) => action.payload,
    [ADD_NEW_HOLIDAYREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_HOLIDAYREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_HOLIDAYREQUEST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const holidayRequestsForm = handleActions(
  {
    [OPEN_HOLIDAYREQUEST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_HOLIDAYREQUEST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_HOLIDAYREQUEST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [HOLIDAYREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LEAVES
export const leaves = handleActions(
  {
    [SET_LEAVE_LIST]: (state, action) => action.payload,
    [ADD_NEW_LEAVE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LEAVE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LEAVE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const leavesForm = handleActions(
  {
    [OPEN_LEAVE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LEAVE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LEAVE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LEAVE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ESTABLISHMENTINFOS
export const establishmentInfo = handleActions(
  {
    [SET_ESTABLISHMENTINFO]: (state, action) => action.payload,
  },
  null
);

export const establishmentInfoForm = handleActions(
  {
    [OPEN_ESTABLISHMENTINFO_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ESTABLISHMENTINFO_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ESTABLISHMENTINFO]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ESTABLISHMENTINFO_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
// BUSINESSS
export const businesss = handleActions(
  {
    [SET_BUSINESS_LIST]: (state, action) => action.payload,
    [ADD_NEW_BUSINESS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BUSINESS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BUSINESS]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const businesssForm = handleActions(
  {
    [OPEN_BUSINESS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BUSINESS_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BUSINESS]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BUSINESS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PROJECTS
export const projects = handleActions(
  {
    [SET_PROJECT_LIST]: (state, action) => action.payload,
    [ADD_NEW_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PROJECT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const projectsForm = handleActions(
  {
    [OPEN_PROJECT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PROJECT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PROJECT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PROJECT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ACTIVITYS
export const activitys = handleActions(
  {
    [SET_ACTIVITY_LIST]: (state, action) => action.payload,
    [ADD_NEW_ACTIVITY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ACTIVITY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ACTIVITY]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const activitysForm = handleActions(
  {
    [OPEN_ACTIVITY_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ACTIVITY_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ACTIVITY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ACTIVITY_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// HOURS
export const hours = handleActions(
  {
    [SET_HOUR_LIST]: (state, action) => action.payload,
    [ADD_NEW_HOUR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_HOUR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_HOUR]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const hoursForm = handleActions(
  {
    [OPEN_HOUR_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_HOUR_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_HOUR]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [HOUR_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CONFIGURATIONS
export const configurations = handleActions(
  {
    [SET_CONFIGURATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_CONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CONFIGURATION]: (state, action) => {
      const newState = action.payload;
      return newState;
    },
    [DELETE_CONFIGURATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const configurationsForm = handleActions(
  {
    [OPEN_CONFIGURATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CONFIGURATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CONFIGURATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CONFIGURATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ESTABLISHMENTS
export const establishments = handleActions(
  {
    [SET_ESTABLISHMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ESTABLISHMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ESTABLISHMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ESTABLISHMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const establishmentsForm = handleActions(
  {
    [OPEN_ESTABLISHMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ESTABLISHMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ESTABLISHMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ESTABLISHMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SUGGESTIONS
export const suggestions = handleActions(
  {
    [SET_SUGGESTION_LIST]: (state, action) => action.payload,
    [ADD_NEW_SUGGESTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SUGGESTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SUGGESTION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const suggestionsForm = handleActions(
  {
    [OPEN_SUGGESTION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SUGGESTION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SUGGESTION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SUGGESTION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// UNITYS
export const unitys = handleActions(
  {
    [SET_UNITY_LIST]: (state, action) => action.payload,
    [ADD_NEW_UNITY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_UNITY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_UNITY]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const unitysForm = handleActions(
  {
    [OPEN_UNITY_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_UNITY_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_UNITY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [UNITY_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LOCATIONS
export const locations = handleActions(
  {
    [SET_LOCATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_LOCATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LOCATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LOCATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const locationsForm = handleActions(
  {
    [OPEN_LOCATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LOCATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LOCATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LOCATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PARAMETERS
export const parameters = handleActions(
  {
    [SET_PARAMETER_LIST]: (state, action) => action.payload,
    [ADD_NEW_PARAMETER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PARAMETER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PARAMETER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const parametersForm = handleActions(
  {
    [OPEN_PARAMETER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PARAMETER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PARAMETER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PARAMETER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LEGISLATIONS
export const legislations = handleActions(
  {
    [SET_LEGISLATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_LEGISLATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LEGISLATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LEGISLATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const legislationsForm = handleActions(
  {
    [OPEN_LEGISLATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LEGISLATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LEGISLATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LEGISLATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CONTAINERS
export const containers = handleActions(
  {
    [SET_CONTAINER_LIST]: (state, action) => action.payload,
    [ADD_NEW_CONTAINER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CONTAINER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CONTAINER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const containersForm = handleActions(
  {
    [OPEN_CONTAINER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CONTAINER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CONTAINER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CONTAINER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SERVICES
export const services = handleActions(
  {
    [SET_SERVICE_LIST]: (state, action) => action.payload,
    [ADD_NEW_SERVICE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SERVICE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SERVICE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const servicesForm = handleActions(
  {
    [OPEN_SERVICE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SERVICE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SERVICE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SERVICE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SAMPLINGPOINTS
export const samplingpoints = handleActions(
  {
    [SET_SAMPLINGPOINT_LIST]: (state, action) => action.payload,
    [ADD_NEW_SAMPLINGPOINT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SAMPLINGPOINT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SAMPLINGPOINT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const samplingpointsForm = handleActions(
  {
    [OPEN_SAMPLINGPOINT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SAMPLINGPOINT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SAMPLINGPOINT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SAMPLINGPOINT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PLANNINGS
export const plannings = handleActions(
  {
    [SET_PLANNING_LIST]: (state, action) => action.payload,
    [ADD_NEW_PLANNING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PLANNING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PLANNING]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const planningsForm = handleActions(
  {
    [OPEN_PLANNING_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PLANNING_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PLANNING]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PLANNING_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// MATRIXS
export const matrixs = handleActions(
  {
    [SET_MATRIX_LIST]: (state, action) => action.payload,
    [ADD_NEW_MATRIX]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_MATRIX]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_MATRIX]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const matrixsForm = handleActions(
  {
    [OPEN_MATRIX_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_MATRIX_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_MATRIX]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [MATRIX_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// FEES
export const fees = handleActions(
  {
    [SET_FEE_LIST]: (state, action) => action.payload,
    [ADD_NEW_FEE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_FEE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_FEE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const feesForm = handleActions(
  {
    [OPEN_FEE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_FEE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_FEE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [FEE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SAMPLINGS
export const samplings = handleActions(
  {
    [SET_SAMPLING_LIST]: (state, action) => action.payload,
    [ADD_NEW_SAMPLING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SAMPLING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SAMPLING]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const samplingsForm = handleActions(
  {
    [OPEN_SAMPLING_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SAMPLING_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SAMPLING]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SAMPLING_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// COLUMNS
export const columns = handleActions(
  {
    [SET_COLUMN_LIST]: (state, action) => action.payload,
    [ADD_NEW_COLUMN]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_COLUMN]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_COLUMN]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const columnsForm = handleActions(
  {
    [OPEN_COLUMN_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_COLUMN_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_COLUMN]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [COLUMN_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// BUSINESSALERTS
export const businessAlerts = handleActions(
  {
    [SET_BUSINESSALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_BUSINESSALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BUSINESSALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BUSINESSALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const businessAlertsForm = handleActions(
  {
    [OPEN_BUSINESSALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BUSINESSALERT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BUSINESSALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BUSINESSALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// STOCKS
export const stocks = handleActions(
  {
    [SET_STOCK_LIST]: (state, action) => action.payload,
    [ADD_NEW_STOCK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_STOCK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_STOCK]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const stocksForm = handleActions(
  {
    [OPEN_STOCK_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_STOCK_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_STOCK]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [STOCK_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EQUIPMENTS
export const equipments = handleActions(
  {
    [SET_EQUIPMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_EQUIPMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EQUIPMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EQUIPMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const equipmentsForm = handleActions(
  {
    [OPEN_EQUIPMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EQUIPMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EQUIPMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EQUIPMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// MAINTENANCES
export const maintenances = handleActions(
  {
    [SET_MAINTENANCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_MAINTENANCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_MAINTENANCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_MAINTENANCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const maintenancesForm = handleActions(
  {
    [OPEN_MAINTENANCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_MAINTENANCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_MAINTENANCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [MAINTENANCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SOCIETYS
export const societys = handleActions(
  {
    [SET_SOCIETY_LIST]: (state, action) => action.payload,
    [ADD_NEW_SOCIETY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SOCIETY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SOCIETY]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const societysForm = handleActions(
  {
    [OPEN_SOCIETY_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SOCIETY_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SOCIETY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SOCIETY_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LABS
export const labs = handleActions(
  {
    [SET_LAB_LIST]: (state, action) => action.payload,
    [ADD_NEW_LAB]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LAB]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LAB]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const labsForm = handleActions(
  {
    [OPEN_LAB_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LAB_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LAB]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LAB_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CONSULTORS
export const consultors = handleActions(
  {
    [SET_CONSULTOR_LIST]: (state, action) => action.payload,
    [ADD_NEW_CONSULTOR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CONSULTOR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CONSULTOR]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const consultorsForm = handleActions(
  {
    [OPEN_CONSULTOR_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CONSULTOR_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CONSULTOR]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CONSULTOR_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CONSULTINVOICEPLANNINGS
export const consultInvoicePlannings = handleActions(
  {
    [SET_CONSULTINVOICEPLANNING_LIST]: (state, action) => action.payload,
    [ADD_NEW_CONSULTINVOICEPLANNING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CONSULTINVOICEPLANNING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CONSULTINVOICEPLANNING]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const consultInvoicePlanningsForm = handleActions(
  {
    [OPEN_CONSULTINVOICEPLANNING_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CONSULTINVOICEPLANNING_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CONSULTINVOICEPLANNING]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CONSULTINVOICEPLANNING_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CONSULTINVOICES
export const consultInvoices = handleActions(
  {
    [SET_CONSULTINVOICE_LIST]: (state, action) => action.payload,
    [ADD_NEW_CONSULTINVOICE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CONSULTINVOICE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CONSULTINVOICE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const consultInvoicesForm = handleActions(
  {
    [OPEN_CONSULTINVOICE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CONSULTINVOICE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CONSULTINVOICE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CONSULTINVOICE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ROLES
export const roles = handleActions(
  {
    [SET_ROLE_LIST]: (state, action) => action.payload,
    [ADD_NEW_ROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ROLE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const rolesForm = handleActions(
  {
    [OPEN_ROLE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ROLE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ROLE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ROLE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// IVAS
export const ivas = handleActions(
  {
    [SET_IVA_LIST]: (state, action) => action.payload,
    [ADD_NEW_IVA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_IVA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_IVA]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const ivasForm = handleActions(
  {
    [OPEN_IVA_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_IVA_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_IVA]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [IVA_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// aditionalInformations
export const aditionalInformations = handleActions(
  {
    [SET_ADITIONALINFORMATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_ADITIONALINFORMATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ADITIONALINFORMATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ADITIONALINFORMATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const aditionalInformationsForm = handleActions(
  {
    [OPEN_ADITIONALINFORMATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ADITIONALINFORMATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ADITIONALINFORMATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ADITIONALINFORMATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// OTHERADITIONALINFORMATIONS
export const otherAditionalInformations = handleActions(
  {
    [SET_OTHERADITIONALINFORMATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_OTHERADITIONALINFORMATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_OTHERADITIONALINFORMATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_OTHERADITIONALINFORMATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const otherAditionalInformationsForm = handleActions(
  {
    [OPEN_OTHERADITIONALINFORMATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_OTHERADITIONALINFORMATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_OTHERADITIONALINFORMATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [OTHERADITIONALINFORMATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LOGSACCES
export const logsAcces = handleActions(
  {
    [SET_LOGSACCESS_LIST]: (state, action) => action.payload,
    [ADD_NEW_LOGSACCESS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LOGSACCESS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LOGSACCESS]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const logsAccesForm = handleActions(
  {
    [OPEN_LOGSACCESS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LOGSACCESS_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LOGSACCESS]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LOGSACCESS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
